import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { I18nextProvider } from "react-i18next";

import {
  loadAndInjectFonts,
  getOppCookie,
  setOppCookie,
} from "@pdpp/lib-react";

import { BasenameContext } from "./BasenameContext";
import { SENTRY_DSN, SENTRY_RELEASE, ENVIRONMENT } from "./utils/runtimeConfig";
import { i18n } from "./language/i18n";
import {
  addLanguageBundlesStandalone,
  getSupportedLanguage,
} from "./language/language";
import "./lib-styles";
import "./index.css";
import { Auth } from "./api/auth";
import { App } from "./App";

const queryClient = new QueryClient();

const initializeApp = async (): Promise<void> => {
  const shouldRunSentry = SENTRY_DSN && SENTRY_RELEASE && ENVIRONMENT;
  const oppCookie = getOppCookie();
  const lang = getSupportedLanguage(oppCookie.lang);
  setOppCookie({ ...oppCookie, lang });

  if (shouldRunSentry) {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: SENTRY_RELEASE,
      environment: ENVIRONMENT,
      tracesSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
    });
  }

  await loadAndInjectFonts("en");
  await addLanguageBundlesStandalone(lang);

  const container = document.getElementById("root");

  if (container !== null) {
    createRoot(container).render(
      <StrictMode>
        <MsalProvider instance={Auth.get().msalInstance}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <BasenameContext.Provider value={""}>
                  <App />
                </BasenameContext.Provider>
              </QueryClientProvider>
            </BrowserRouter>
          </I18nextProvider>
        </MsalProvider>
      </StrictMode>,
    );
  }
};

initializeApp().catch(console.error);
