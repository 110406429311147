import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Select, { OptGroup, Option } from "@ingka/select";

import { css } from "../__generated-styled-system/css";
import { Card } from "./Card";
import { ClientBusinessNode } from "../features/org/types";
import { orgId$ } from "../features/org/state";

const unitSelectStyles = css({
  padding: "space550!",
});

interface UnitsInCountry {
  readonly country: string;
  readonly businessUnits: Array<ClientBusinessNode>;
}

function getUnitsInCountry(
  units: Array<ClientBusinessNode>,
  userCountry: string,
): Array<UnitsInCountry> {
  const unitsByUser = units.filter((n) =>
    userCountry !== "ALL_COUNTRIES" ? n.parentId === userCountry : true,
  );

  const unitsCountry = unitsByUser.reduce(
    (acc: Array<UnitsInCountry>, unit) => {
      const country =
        new Intl.DisplayNames(i18next.language, { type: "region" }).of(
          unit.parentId ?? "",
        ) ?? "";

      const unitsInCountry = acc.find((u) => u.country === country);

      if (unitsInCountry) {
        unitsInCountry.businessUnits.push(unit);
      } else {
        acc.push({ country, businessUnits: [unit] });
      }

      return acc;
    },
    [],
  );

  //API is not returning the data sorted by country and unit name
  const sortedList = unitsCountry
    .map((country) => ({
      ...country,
      businessUnits: country.businessUnits.sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    }))
    .sort((a, b) => a.country.localeCompare(b.country));

  return sortedList;
}

interface UnitSelectProps {
  units: Array<ClientBusinessNode>;
  userCountry: string;
}

export const UnitSelect: FC<UnitSelectProps> = ({ units, userCountry }) => {
  const [t] = useTranslation();

  function onUnitChange(event: ChangeEvent<HTMLSelectElement>): void {
    orgId$.value = event.target.value;
  }

  return (
    <div className={unitSelectStyles}>
      <Card
        title={t("labels.noUnitTitle")}
        message={t("labels.noUnitMessage")}
        content={
          <Select
            id="unitSelect"
            data-analytics="plan/select-unit"
            data-testid="selectOrgNode"
            label=""
            onChange={onUnitChange}
            type="text"
            value=""
            hintText={t("labels.selectUnitHint")}
          >
            {getUnitsInCountry(units, userCountry).map((country) => (
              <OptGroup key={country.country} groupName={country.country}>
                {country.businessUnits.map((unit) => (
                  <Option
                    key={unit.id}
                    label={unit.name}
                    value={unit.id}
                    data-analytics={`plan/select-unit/${unit.name}`}
                  />
                ))}
              </OptGroup>
            ))}
          </Select>
        }
      />
    </div>
  );
};
