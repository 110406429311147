import { i18n } from "./i18n";
import { setDefaultYupTranslations } from "./setDefaultYupTranslations";

export const DEFAULT_LANGUAGE_KEY = "en";

export const SUPPORTED_LANGUAGES = [
  {
    key: DEFAULT_LANGUAGE_KEY,
    value: "English",
  },
  {
    key: "sv-SE",
    value: "Svenska (Sverige)",
  },
  {
    key: "de-DE",
    value: "Deutsch (Deutschland)",
  },
  {
    key: "fr-CH",
    value: "Français (Suisse)",
  },
  {
    key: "it-CH",
    value: "Italiano (Svizzera)",
  },
  {
    key: "ja-JP",
    value: "日本語 (日本)",
  },
] as const;

export const supportedLanguages = Object.values(SUPPORTED_LANGUAGES).map(
  ({ key }) => key,
);

export type SupportedLanguage = (typeof supportedLanguages)[number];

interface LanguageContent {
  readonly [key: string]: string | LanguageContent;
}

/**
 * Lazy loads the language files so
 * they end up in a separate file (chunk)
 * and only one file (chunk) will be loaded
 * depending on the selected language
 */

export const getSupportedLanguage = (value: string): SupportedLanguage => {
  if (!supportedLanguages.includes(value as SupportedLanguage)) {
    return DEFAULT_LANGUAGE_KEY;
  }

  return value as SupportedLanguage;
};

/**
 * Load language bundles from `public/locales` folder, so they can be replaced without re-deployment of the application
 * Function should be used for stand-alone deployment of Workload Planning
 */
export const addLanguageBundlesStandalone = async (
  lang: SupportedLanguage,
): Promise<void> => {
  const resources: LanguageContent = await (
    await fetch(`/locales/${lang}.json?t=${Date.now()}`)
  ).json();
  i18n.addResourceBundle(lang, "common", resources, true);
  await i18n.changeLanguage(lang);
  setDefaultYupTranslations();
};

/**
 * Load language bundles source code, bundled for the OPP parcel.
 * Function should be used when loading Workload Planning as the component in OPP.
 *
 * If statement for each file is used in order for Vite to be able to bundle the files.
 */
export const addLanguageBundlesParcel = async (
  lang: SupportedLanguage,
): Promise<void> => {
  let resources: LanguageContent = await import("../locales/en.json");
  if (lang === "sv-SE") {
    resources = await import("../locales/sv-SE.json");
  }
  if (lang === "de-DE") {
    resources = await import("../locales/de-DE.json");
  }
  if (lang === "fr-CH") {
    resources = await import("../locales/fr-CH.json");
  }
  if (lang === "it-CH") {
    resources = await import("../locales/it-CH.json");
  }
  if (lang === "ja-JP") {
    resources = await import("../locales/ja-JP.json");
  }
  i18n.addResourceBundle(lang, "common", resources, true);
  await i18n.changeLanguage(lang);
  setDefaultYupTranslations();
};
