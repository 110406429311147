import { useQuery } from "@tanstack/react-query";

import { UserGroupsRoleStructure } from "@pdpp/lib-planta";

import { plantaApi } from "../../api/fetch";
import { userPermissions$ } from "./state";
import { OPP_AUTHZ } from "../../utils/runtimeConfig";

export const useGetUserPermissions = (): {
  data: UserGroupsRoleStructure | null;
  refetch: () => void;
  loading: boolean;
} => {
  const {
    data,
    refetch,
    isLoading: loading,
  } = useQuery<UserGroupsRoleStructure | null>({
    enabled: OPP_AUTHZ,
    initialData: null,
    queryKey: [`opp-user-permissions`],
    queryFn: async () => {
      const res =
        await plantaApi.jsonInJsonOut<UserGroupsRoleStructure>(
          `/users/permissions`,
        );
      userPermissions$.value = res;
      return res;
    },
  });

  return {
    data,
    refetch,
    loading,
  };
};
